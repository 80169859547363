import { Logger } from "./Logger";

export const LanderCache = {
  DefaultTtl: 5 * 60, // 5 min default.
  DefaultParkwebTtl: 20, // 20 second, PW can be targeted if a user decides to login.
};

/**
 * fetchCached uses the Fetch API to make HTTP request and if successful
 * caches the data onto localStorage. An optional `ttl` can be passed for
 * cache expiration value in seconds.
 * @param url
 * @param options
 * @returns {Promise<Response>|undefined|PromiseLike<any>|Promise<any>|*|void}
 */
export function fetchCached(url, options) {
  const ttl = (options || {}).ttl || LanderCache.DefaultTtl;

  // Using url as a cache key
  const cacheKey = url;

  // Handle any storage errors
  try {
    let cached = localStorage.getItem(cacheKey);
    if (cached !== null) {
      cached = JSON.parse(cached);
      const age = (Date.now() - cached.ttl) / 1000;
      if (age < ttl) {
        Logger.debug("Cache hit: " + cacheKey);
        const response = new Response(new Blob([cached.data]));
        return Promise.resolve(response);
      }
      // clean-up stale cache value
      localStorage.removeItem(cacheKey);
    }
  } catch (e) {
    Logger.warn("error using local storage: " + toString(e));
  }

  // Make API request and cache the result if successful 200
  return fetch(url, options).then((response) => {
    if (response.status === 200) {
      response
        .clone()
        .text()
        .then((content) => {
          const cacheValue = { ttl: Date.now(), data: content };
          try {
            localStorage.setItem(cacheKey, JSON.stringify(cacheValue));
          } catch (e) {
            Logger.warn("error setting value to local storage: " + toString(e));
          }
        });
    }
    return response;
  });
}
